

._2RWmX {
  opacity: 0.4 !important;
  color: rgba(0,0,0,0.75) !important;
}

._2LpS7 {
  max-height: 36px !important;
  height: 36px !important;
  min-height: 36px !important;
  font-size: 14px !important;
} 

._1Dccc {
  max-height: 30px !important;
  height: 30px !important;
  min-height: 30px !important;
  font-size: 14px !important;
} 

._2HSWc {
  max-height: 24px !important;
  height: 24px !important;
  min-height: 24px !important;
  font-size: 12px !important;
} 

._3yj9P {
  color: white !important;
  background-color: rgba(0,0,0,0) !important;
}

button {
  cursor: pointer;
}

._1Pz2d {
  height: 48px;
  min-height: 48px;
  font-size: 16px;
  padding: 0 20px;
  text-align: center;
  font-weight: bold;
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 24px;

  
}

._1Pz2d > :nth-child(1) {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

._1Pz2d:hover, ._ZMOEZ:hover, ._38f37:hover, ._1Pz2d:focus, ._ZMOEZ:focus, ._38f37:focus{
  /* box-shadow: 0 0 0 3px rgba(4,157,212,.3); */
  box-shadow: 0px 0px 0 3px rgba(4,157,212,.3);
  outline: none;
}

._1Pz2d:active, ._ZMOEZ:active, ._38f37:active{
  transform: scale(.95);
  transition: .1s ease;
  box-shadow: none;
}

._1HEK_ {
  border-radius: 0;
}

._39XKQ {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
}

._2qA6O {
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
}

._38yRp {
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
}

._1F4aM {
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  font-size: 12px;
}

._1P2U1 {
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
}

._39bnp {
  color: white;
  background: transparent linear-gradient(100deg, #24B9E1 0%, #7166C4 100%) 0% 0% no-repeat padding-box;
}

._21664 {
  color: white;
  background: #8F919E 0% 0% no-repeat padding-box;
}

._1hoGZ {
  color: rgba(143, 145, 158, 0.5);
  background: rgba(143, 145, 158, 0.2) 0% 0% no-repeat padding-box;
}

._oBYU2 {
  color:#049DD4;
  background: rgba(0,0,0,0);
}

._DvBYY {
  color:#24B9E1;
  background: white;
}

._MHNEW {
  color:white;
  background:#F7BE2D;
}




._ZMOEZ {
  background: transparent linear-gradient(135deg, #24B9E1 0%, #7166C4 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}

._38f37 {
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}

._ZMOEZ  > :nth-child(1) {
  width: 100%;
  height: 100%;
  padding: 15%;
  box-sizing: border-box;
}

._38f37  > :nth-child(1) {
  width: 100%;
  height: 100%;
  padding: 15%;
  box-sizing: border-box;
}


/* FORM INPUT LIGHT MODE */
/* FORM INPUT LIGHT MODE */
/* FORM INPUT LIGHT MODE */
/* FORM INPUT LIGHT MODE */

._32_3E {
  display: flex;
  flex-direction: column;
  color: #001741;
  font-size: 14px;
  font-weight: bold;
  width: calc(100%);
}

._32_3E > :nth-child(1){
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #CBCCD1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  font-size: 14px;
  font-weight: 600;
  color: #020726;
  padding: 0 15px;
  box-sizing: border-box;
  outline: none;
}

._32_3E > :nth-child(1)::-moz-placeholder{
  font-style: italic;
}

._32_3E > :nth-child(1):-ms-input-placeholder{
  font-style: italic;
}

._32_3E > :nth-child(1)::placeholder{
  font-style: italic;
}

._32_3E > :nth-child(1):focus{
  box-shadow: 0px 0px 0 3px rgba(4,157,212,.3);
  outline: none;
}

/* FORM INPUT DARK MODE */
/* FORM INPUT DARK MODE */
/* FORM INPUT DARK MODE */
/* FORM INPUT DARK MODE */

._2ZEov {
  color: white !important;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
  width: calc(100%);
}

._2ZEov > :nth-child(1)::-moz-placeholder{
  font-style: italic;
}

._2ZEov > :nth-child(1):-ms-input-placeholder{
  font-style: italic;
}

._2ZEov > :nth-child(1)::placeholder{
  font-style: italic;
}

._2ZEov > :nth-child(1):focus{
  box-shadow: 0px 0px 0 3px rgba(4,157,212,.3);
  outline: none;
}

._2ZEov > :nth-child(1){
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #53566B;
  background: #020726 0% 0% no-repeat padding-box;
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  padding: 0 15px;
  box-sizing: border-box;
  outline: none;
}


._cD1yu {
  background: rgba(2, 7, 38, 0.05) 0% 0% no-repeat padding-box !important;
}


/* FORM TEXTAREA */
/* FORM TEXTAREA */
/* FORM TEXTAREA */
/* FORM TEXTAREA */

._34vFI textarea {
  min-height: 80px !important;
  padding: 10px !important;

}


/* FORM CHECKBOX LIGHT MODE */
/* FORM CHECKBOX LIGHT MODE */
/* FORM CHECKBOX LIGHT MODE */
/* FORM CHECKBOX LIGHT MODE */

._3NosP {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
  
}

._3NosP > div {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
  padding: 0 !important;
  margin-left: 10px !important;
}

._3NosP > input{
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  border: 8px solid white !important;
  border-radius: 2px !important;
  outline: none !important;
  /* background: red !important; */
  position: relative !important;
  box-shadow: 0px 0px 0px 1px #888888 !important;
  padding: 0 0 2px 2px ;
  box-sizing: border-box;
}



._3NosP > input:checked {
  border: 8px solid white !important;
  
  /* background-color: #34495E !important; */
}

._3NosP > input:checked::before{
  content: '\2713' !important;
  color: black !important;
  position: absolute !important;
  left: -4px !important;
  top: -7px !important;
  width: 5px !important;
  height: 5px !important;
}

._3NosP > div > :nth-child(1) {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #001741 !important;
  margin: 0 !important;
}

._3NosP > div > :nth-child(2) {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #020726 !important;
  margin: 0 !important;
  text-align: justify !important;
}

/* FORM CHECKBOX DARK MODE */
/* FORM CHECKBOX DARK MODE */
/* FORM CHECKBOX DARK MODE */
/* FORM CHECKBOX DARK MODE */

._3IIVU {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none !important; /* Old versions of Firefox */
        -ms-user-select: none !important; /* Internet Explorer/Edge */
            user-select: none !important;
  
}

._3IIVU > div {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
  padding: 0 !important;
  margin-left: 10px !important;
}

._3IIVU > input{
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  border: 8px solid #020726 !important;
  border-radius: 2px !important;
  outline: none !important;
  background: none !important;
  position: relative !important;
  box-shadow: 0px 0px 0px 1px #888888 !important;
  padding: 0 0 2px 2px ;
  box-sizing: border-box;
}


._3IIVU > input:checked {
  border: 8px solid #020726 !important;
  background-color: #34495E !important;
}

._3IIVU > input:checked::before{
  content: '\2713' !important;
  color: white !important;
  position: absolute !important;
  left: -4px !important;
  top: -7px !important;
  width: 5px !important;
  height: 5px !important;
}

._3IIVU > div > :nth-child(1) {
  font-size: 14px !important;
  font-weight: bold !important;
  color: white !important;
  margin: 0 !important;
}

._3IIVU > div > :nth-child(2) {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: white !important;
  margin: 0 !important;
  text-align: justify !important;
}

/* FORM RADIO LIGHT MODE */
/* FORM RADIO LIGHT MODE */
/* FORM RADIO LIGHT MODE */
/* FORM RADIO LIGHT MODE */

._2uu7E {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  
}

._2uu7E > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 0;
  margin-left: 10px;
}

._2uu7E > input{
  outline: none;
  position: relative;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

}

._2uu7E > input:checked{
  outline: none;
}

._2uu7E > input::before{
  content: '';
  position: absolute;
  border-radius: 50%;
  background: white;
}

._2uu7E > input:checked::before{
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: calc(50% - 5px);
  top: calc(50% - 5px);
  background: black;
  border: 1px solid black;
}


._2uu7E > div > :nth-child(1) {
  font-size: 14px;
  font-weight: bold;
  color: #001741;
  margin: 0;
}

._2uu7E > div > :nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: #020726;
  margin: 0;
  text-align: justify;
}

/* FORM RADIO DARK MODE */
/* FORM RADIO DARK MODE */
/* FORM RADIO DARK MODE */
/* FORM RADIO DARK MODE */

._2VXtv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  
}

._2VXtv > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 0;
  margin-left: 10px;
}

._2VXtv > input{
  outline: none;
  position: relative;
  width: 16px;
  height: 16px;

}

._2VXtv > input:checked{
  outline: none;
}

._2VXtv > input::before{
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid #53566B;
  border-radius: 50%;
  background: #001741;
}

._2VXtv > input:checked::before{
  content:"";
  position: absolute;
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  border-radius: 50%;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  border-radius: 50%;
  border: 3px solid #53566B;
  background: white;
}

._2VXtv > div > :nth-child(1) {
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin: 0;
}

._2VXtv > div > :nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: white;
  margin: 0;
  text-align: justify;
}


._1oBGy {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ALERT STYLE */
/* ALERT STYLE */
/* ALERT STYLE */
/* ALERT STYLE */

._2rI0b {
  width: 320px;
  max-width: 320px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #00000033;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

._2rI0b > ._2mo3X {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #001741;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 30px;
}

._2rI0b > ._2mo3X > :nth-child(1){
  margin-bottom: 18px;
}

._2rI0b > ._2mo3X > :nth-child(2){
  margin-top: 0;
}

._2rI0b > ._24OKz {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 15px 0;
  box-sizing: content-box;
  border-top: 1px solid #F0F0F0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

._2rI0b > ._24OKz > button {
  margin-bottom: 0px;
  padding: 0 40px;
}



/* CONFIRM STYLE */
/* CONFIRM STYLE */
/* CONFIRM STYLE */
/* CONFIRM STYLE */

._3K3_g {
  width: 320px;
  max-width: 320px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #00000033;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

._3K3_g > ._2mo3X {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #001741;
  font-size: 16px;
  font-weight: bold;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

._3K3_g > ._2mo3X > :nth-child(1){
  margin-bottom: 18px;
}

._3K3_g > ._2mo3X > :nth-child(2){
  margin-top: 0;
}

._3K3_g > ._24OKz {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 15px 0;
  border-top: 1px solid #F0F0F0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;

}

._3K3_g > ._24OKz > button {
  margin-bottom: 0px;
  padding: 0 40px;
}


/*<============================ MODAL STYLE =========================================> */
/*<============================ MODAL STYLE =========================================> */
/*<============================ MODAL STYLE =========================================> */
/*<============================ MODAL STYLE =========================================> */

._1DUXS {
  min-height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #00000033;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  position: relative;
  transition: height 0.3s;
  max-width: 100%;
}

._1DUXS > ._3viwp {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;

}

._1DUXS > ._1aDvk{
  font-size: 16px;
  color: #001741;
  font-weight: bold;
  text-align: justify;
  width: calc(100% - 40px);
  padding: 20px;
  box-sizing: content-box;
  border-bottom: 1px solid #F4F4F4;
}

._1DUXS > ._1vZDf{
  font-size: 16px;
  color: #001741;
  font-weight: bold;
  text-align: justify;
  width: calc(100% - 70px);
  padding: 20px;
  box-sizing: content-box;
  border-bottom: 1px solid #F4F4F4;
  margin-right: 30px;
  
}

._1DUXS > ._2mo3X{
  font-size: 14px;
  color: #001741;
  font-weight: 500;
  text-align: justify;
  width: calc(100% - 40px);
  padding: 20px 20px 30px 20px;
  box-sizing: content-box;
}

._1DUXS > ._24OKz{
  width: calc(100% - 40px);
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 15px 20px 15px 20px;

  border-top: 1px solid #F4F4F4;
}

._2R1Fn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

._2R1Fn > button {
  margin-bottom: 0 !important;
  margin-right: 15px;
}

._17jS4 {
  width: 100%;
  height: calc(60px + env(safe-area-inset-top));
  min-height: calc(60px + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #001741;
  font-weight: 800;
  box-shadow: 0 0 10px 0px #00000040;
}

._17jS4 > :nth-child(1){
  position: absolute;
  left: 20px;
  width: 24px;
  height: 24px;
}

._3DxlQ {
  width: 24px;
  height: 24px;
  -webkit-animation: _1sp4O 1s linear infinite;
          animation: _1sp4O 1s linear infinite;
}

@media only screen and (min-width: 1025px) {
  ._17jS4 {
    display: none;
  }
}


@media only screen and (max-width: 320px) {
  ._2rI0b {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }

  ._3K3_g {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }

  ._1DUXS {
    border-radius: 0;
  }
}
@-webkit-keyframes _1sp4O { 100% { -webkit-transform: rotate(360deg); } }
@keyframes _1sp4O { 100% { transform:rotate(360deg); } }

